import React, { useEffect, useState } from "react"
import queryString from "query-string"

import ArticleTemplate from "../../templates/article"
import PageTemplate from "../../templates/page"
import EventTemplate from "../../templates/event"
import HomepageTemplate from "./homepage-preview"
import { useLocale } from "../../context/locale/LocaleProvider"

import { client } from "../../lib/sanity"

const SanityPreview = () => {
  const [data, setData] = useState()
  const [type, setType] = useState("")
  const { state, dispatch } = useLocale()

  // Status can be one of the following
  // ['loading', 'complete', 'not-found', 'errored']
  const [status, setStatus] = useState("loading")

  useEffect(() => {
    const parsed = queryString.parse(window.location.search)
    dispatch({ type: "set", locale: parsed.locale })
  }, [])

  const getData = async () => {
    const parsed = queryString.parse(window.location.search)
    const params = {
      type: parsed.type,
      id: parsed.id,
    }

    // Run the query to Sanity
    const query = `*[_type == $type && _id == $id][0]{..., content[]{..., pageTestimonials[]->{_rawTestimonialText[], testimonialText, customerName, customerSector, slideImage, id}, staff[]->{_ref, name, avatar, role, quote, bio, linkedInUrl}}, usefulLinks[]->{id, title, slug, parentPage->{slug}}, pageTestimonials[]->{id, title, buttonText, customerName, customerSector, '_rawTestimonialText': testimonialText, slideImage { '_rawAsset': asset, asset }}}`

    try {
      const res = await client.fetch(query, params)

      if (res) {
        setData(res)
        setStatus("complete")
      } else {
        setStatus("not-found")
      }
    } catch (err) {
      setStatus("errored")
      console.error("Unable to retrieve preview data", err)
    }
  }

  useEffect(() => {
    // Only run this code in the client
    if (typeof window !== "undefined") {
      const parsed = queryString.parse(window.location.search)
      getData()
      setType(parsed.type)
    }
  }, [])

  if (status === "loading") {
    return <p>Please wait...</p>
  }

  if (status === "not-found") {
    return <p>No content found for this document...</p>
  }

  if (status === "errored") {
    return <p>Unable to retrieve document. Please check the browser console.</p>
  }

  if (type === "article") {
    // If we convert sanity groq data into the graphql structure,
    // we can use the build template to preview content.
    const articleData = {
      sanityArticle: {
        title: data.title,
        subTitle: data.subTitle,
        quote: data.quote,
        _rawContent: data.content,
        featureImage: data.featureImage
          ? {
              _rawAsset: data.featureImage.asset,
            }
          : null,
      },
    }

    return (
      <ArticleTemplate
        data={articleData}
        pageContext={{ locale: state.locale }}
      />
    )
  }

  if (type === "event") {
    // If we convert sanity groq data into the graphql structure,
    // we can use the build template to preview content.
    const eventData = {
      sanityEvent: {
        id: data.id,
        title: data.title,
        subTitle: data.subTitle,
        involvement: data.involvement,
        eventStart: data.eventStart,
        eventEnd: data.eventEnd,
        _rawAbstract: data.abstract,
        slug: data.slug,
        featureImage: data.featureImage
          ? {
              _rawAsset: data.featureImage.asset,
            }
          : null,
      },
    }

    return (
      <EventTemplate data={eventData} pageContext={{ locale: state.locale }} />
    )
  }

  // If we convert sanity groq data into the graphql structure,
  // we can use the build template to preview content.
  if (type === "homepage") {
    const homepageData = {
      sanityHomepage: {
        videoSlides: data.videoSlides,
        _rawContent: data.content,
        duration: data.duration,
        seo: {
          description: "",
          keywords: [],
        },
        featureImage: data.featureImage
          ? {
              _rawAsset: data.featureImage.asset,
            }
          : null,
      },
    }

    return (
      <HomepageTemplate
        data={homepageData}
        pageContext={{ locale: state.locale }}
      />
    )
  }

  const pageData = {
    sanityPage: {
      title: data.title,
      subTitle: data.subTitle,
      slug: data.slug,
      colourScheme: data.colourScheme,
      _rawContent: data.content,
      pageTestimonials: data.pageTestimonials,
      usefulLinks: data.usefulLinks,
      featureImage: data.featureImage
        ? {
            _rawAsset: data.featureImage.asset,
          }
        : null,
    },
  }

  return <PageTemplate data={pageData} pageContext={{ locale: state.locale }} />
}

export default SanityPreview
