import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Box, Flex, Text } from "@chakra-ui/react"
import { Container } from "../components/Container"
import { HeroImage } from "../components/HeroImage"
import { Heading } from "../components/Heading"
import { Layout } from "../layouts/default"
import langStrings from "../locales/lang.json"

// Sanity helpers
import PortableText from "../components/PortableText"
import { getFluidProps } from "../lib/sanity"
import { FormulationChallengeBlock } from "../components/Blocks/FormulationChallengeBlock"
import { ArrowBackIcon } from "@chakra-ui/icons"

function ArticleTemplate({ data, pageContext }) {
  const { locale } = pageContext
  const {
    title,
    subTitle,
    featureImage,
    _rawLocalizedContent,
    _rawContent,
    quote,
    seo,
  } = data.sanityArticle

  // Build up fluid props for the gatsby image
  const fluidProps = featureImage?._rawAsset
    ? {
        id: featureImage._rawAsset._ref,
        maxWidth: 1800,
      }
    : {}

  return (
    <Layout>
      <Helmet>
        <title>{title[locale]}</title>
        <meta name="keywords" content={seo ? seo.keywords : ""} />
        <meta name="description" content={seo ? seo.description : ""} />
      </Helmet>

      {featureImage && <HeroImage src={getFluidProps(fluidProps)} />}

      <Container px={0}>
        {title && (
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            justifyContent={{ lg: "space-between" }}
          >
            <Box
              bg="white"
              width={{ md: "50%" }}
              p={{ base: 4, md: 12 }}
              pb={0}
              pos="relative"
              zIndex={10}
            >
              <Link
                to="/about-us/latest-news"
                style={{ textDecoration: "none" }}
              >
                <Text mb={4} fontSize={14} color="brand.400" fontWeight="bold">
                  <ArrowBackIcon mr={2} />
                  {langStrings["latest-news"].back[locale]}
                </Text>
              </Link>
              <Heading fontSize="40px" mb={2}>
                {title[locale] || title.en}
              </Heading>

              {subTitle && subTitle[locale] ? (
                <Text>{subTitle[locale]}</Text>
              ) : (
                <Text>{subTitle.en}</Text>
              )}
            </Box>
          </Flex>
        )}

        <Container bg="white">
          {_rawLocalizedContent && (
            <Box mb={quote ? 14 : 24}>
              <PortableText
                blocks={_rawLocalizedContent[locale]}
                locale={locale}
              />
            </Box>
          )}

          {_rawContent && (
            <Box mb={quote ? 14 : 24}>
              <PortableText blocks={_rawContent} locale={locale} />
            </Box>
          )}
        </Container>

        {quote && (
          <Container bg="white" mb={16}>
            <Flex
              bgColor="brand.400"
              height="100%"
              pb={{ base: 8, md: 0, lg: 0 }}
            >
              <Box
                py={{ base: 4, md: 12, lg: 12 }}
                px={{ base: 4, md: 20, lg: 20 }}
                display="flex"
                flexDir="column"
                flex={1}
                color="white"
              >
                <Text fontSize={22}>
                  {quote?.copy && (quote?.copy[locale] || quote?.copy.en)}
                </Text>

                <Box
                  height="2px"
                  backgroundColor="white"
                  width="50px"
                  my={3}
                  opacity={0.75}
                />

                <Text>
                  {quote?.caption &&
                    (quote?.caption[locale] || quote?.caption.en)}
                </Text>
              </Box>
            </Flex>
          </Container>
        )}

        <Container pt={4} borderTopWidth={2} borderTopColor="gray.300">
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($id: String!) {
    sanityArticle(id: { eq: $id }) {
      title {
        en
        fr
        es
        it
        de
        nl
        ko
        ja
      }
      subTitle {
        en
        fr
        es
        it
        de
        nl
        ko
        ja
      }
      seo {
        keywords
        description
      }
      quote {
        caption {
          en
          fr
          es
          it
          de
          nl
          ko
          ja
        }
        copy {
          en
          fr
          es
          it
          de
          nl
          ko
          ja
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLocalizedContent(resolveReferences: { maxDepth: 10 })
      featureImage {
        _rawAsset
      }
    }
  }
`

export default ArticleTemplate
