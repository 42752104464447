import React from "react"
import { Helmet } from "react-helmet"
import { Box } from "@chakra-ui/react"
import { getFileAsset } from "@sanity/asset-utils"

import PortableText from "../../components/PortableText"
import { Player } from "../../components/VideoPlayer/Player"
import { LatestNews } from "../../components/LatestNews"
import { Container } from "../../components/Container"
import { HeroVideo } from "../../components/HeroVideo"
import { Layout } from "../../layouts/default"
import { useLocale } from "../../context/locale/LocaleProvider"

function Homepage({ data = undefined }) {
  const { state } = useLocale()

  if (data) {
    const { _rawContent, seo } = data.sanityHomepage

    const slides = data.sanityHomepage.videoSlides.map(slide => {
      const asset = getFileAsset(slide.videoFile.asset, {
        projectId: "66r0hwyz",
        dataset: "production",
      })

      return {
        ...slide,
        videoFile: {
          asset,
        },
      }
    })

    return (
      <Layout locale={state.locale}>
        <Helmet>
          <title>Micropore Technologies - Home page</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
        </Helmet>

        {data.sanityHomepage.videoSlides?.length > 0 && (
          <HeroVideo
            slides={slides}
            duration={data.sanityHomepage.duration}
            locale={state.locale}
          />
        )}

        <Container bg="white" pt={2} pos="relative" zIndex={10}>
          {_rawContent && (
            <PortableText blocks={_rawContent} locale={state.locale} />
          )}
        </Container>

        <Box mb={16}>
          <LatestNews locale={state.locale} />
        </Box>

        {data.sanityHomepage.videoEmbed?.embedId && (
          <Container mb={16}>
            <Player
              provider="youtube"
              srcId={data.sanityHomepage.videoEmbed.embedId}
            />
          </Container>
        )}
      </Layout>
    )
  }

  return <p>Data not found...Please contact StackUp Digital</p>
}

export default Homepage
